<template>
  <div>
    <pdf ref="pdf" v-for="i in numPages" :key="i" :src="url" :page="i"></pdf>
  </div>
</template>

<script>
import pdf from "vue-pdf";
export default {
  components: {
    pdf,
  },
  name: "privePDF",
  data() {
    return {
      url: "http://image.cache.timepack.cn/nodejs.pdf",
      numPages: null, // pdf 总页数
    };
  },
  mounted() {
    this.getNumPages();
  },
  methods: {
    // 计算pdf页码总数
    getNumPages() {
      let loadingTask = pdf.createLoadingTask(this.url);
      loadingTask.promise
        .then((pdf) => {
          this.numPages = pdf.numPages;
        })
        .catch((err) => {
          console.error("pdf 加载失败", err);
        });
    },
  },
};
</script>

